import React, { useState, useEffect } from 'react';
import { Card, ListGroup, Button, Row, Col, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

const API_URL = '/api';

const UserDashboard = ({ user }) => {
  const [workshops, setWorkshops] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchUserWorkshops();
  }, [user.id]);

  const fetchUserWorkshops = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_URL}/users/${user.id}/workshops`);
      setWorkshops(response.data);
      setError('');
    } catch (error) {
      console.error('Error fetching user workshops:', error);
      setError('Nie udało się pobrać danych warsztatów. Spróbuj ponownie później.');
    } finally {
      setIsLoading(false);
    }
  };

  const getTaskStatus = (task) => {
    return task.uploaded_photos >= task.required_photos;
  };

  if (isLoading) {
    return <div>Ładowanie danych...</div>;
  }

  if (error) {
    return <div className="text-danger">{error}</div>;
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title>Podsumowanie warsztatów</Card.Title>
        {workshops.length === 0 ? (
          <Card.Text>Nie uczestniczysz jeszcze w żadnym warsztacie.</Card.Text>
        ) : (
          workshops.map((workshop) => (
            <Card key={workshop.id} className="mb-3">
              <Card.Body>
                <Card.Title>{workshop.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {new Date(workshop.start_date).toLocaleDateString()} - {new Date(workshop.end_date).toLocaleDateString()}
                </Card.Subtitle>
                <Row>
                  <Col md={6}>
                    <h6>Zadania ukończone:</h6>
                    <ListGroup variant="flush">
                      {workshop.tasks.filter(task => getTaskStatus(task)).map((task) => (
                        <ListGroup.Item key={task.id}>
                          <Link to={`/task/${task.id}`}>{task.title}</Link>
                          <Badge bg="success" className="ms-2">Ukończone</Badge>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Col>
                  <Col md={6}>
                    <h6>Zadania do wykonania:</h6>
                    <ListGroup variant="flush">
                      {workshop.tasks.filter(task => !getTaskStatus(task)).map((task) => (
                        <ListGroup.Item key={task.id}>
                          <Link to={`/task/${task.id}`}>{task.title}</Link>
                          <Badge bg="warning" text="dark" className="ms-2">
                            {task.uploaded_photos}/{task.required_photos}
                          </Badge>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Col>
                </Row>
                <Button as={Link} to={`/workshop/${workshop.id}`} variant="primary" className="mt-3">
                  Szczegóły warsztatu
                </Button>
              </Card.Body>
            </Card>
          ))
        )}
      </Card.Body>
    </Card>
  );
};

export default UserDashboard;
