import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col md={8} className="mx-auto">
          <h2 className="text-center mb-4">Polityka Prywatności - Warsztaty Fotograficzne Online</h2>
          <p>
            Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych przekazanych przez Uczestników w związku z korzystaniem z naszej platformy warsztatów fotograficznych online.
          </p>
          <h4>1. Gromadzenie danych</h4>
          <p>
            Gromadzimy dane osobowe niezbędne do świadczenia usług warsztatów fotograficznych online, w tym: imię, nazwisko, adres e-mail, numer telefonu oraz opcjonalnie informacje o sprzęcie fotograficznym i poziomie zaawansowania.
          </p>
          <h4>2. Wykorzystanie danych</h4>
          <p>
            Zebrane dane osobowe wykorzystujemy w celu:
            <ul>
              <li>Świadczenia usług warsztatów fotograficznych online</li>
              <li>Komunikacji z Uczestnikami odnośnie szczegółów warsztatów</li>
              <li>Personalizacji treści warsztatów do poziomu i zainteresowań Uczestników</li>
              <li>Przesyłania materiałów edukacyjnych i zadań praktycznych</li>
              <li>Analizy i poprawy jakości naszych usług</li>
              <li>Marketingu bezpośredniego naszych usług, jeśli Uczestnik wyraził na to zgodę</li>
            </ul>
          </p>
          <h4>3. Udostępnianie i przechowywanie zdjęć</h4>
          <p>
            Zdjęcia przesyłane przez Uczestników w ramach zadań warsztatowych są przechowywane na naszych zabezpieczonych serwerach. Dostęp do tych zdjęć mają tylko prowadzący warsztaty oraz inni Uczestnicy danej grupy warsztatowej, chyba że Uczestnik wyrazi zgodę na szersze udostępnienie swojich prac.
          </p>
          <h4>4. Ochrona danych</h4>
          <p>
            Stosujemy odpowiednie środki techniczne i organizacyjne, aby chronić dane osobowe Uczestników oraz ich prace fotograficzne przed nieuprawnionym dostępem, utratą lub zniszczeniem.
          </p>
          <h4>5. Prawa Uczestnika</h4>
          <p>
            Każdy Uczestnik ma prawo do:
            <ul>
              <li>Dostępu do swoich danych osobowych</li>
              <li>Poprawiania i aktualizacji swoich danych</li>
              <li>Usunięcia swoich danych z naszej bazy</li>
              <li>Ograniczenia przetwarzania swoich danych</li>
              <li>Wycofania zgody na przetwarzanie danych w dowolnym momencie</li>
              <li>Przenoszenia swoich danych</li>
              <li>Wniesienia sprzeciwu wobec przetwarzania danych</li>
            </ul>
          </p>
          <h4>6. Pliki cookies</h4>
          <p>
            Nasza platforma wykorzystuje pliki cookies do poprawy funkcjonalności i analizy ruchu. Uczestnik może w każdej chwili zmienić ustawienia cookies w swojej przeglądarce.
          </p>
          <h4>7. Zmiany w Polityce Prywatności</h4>
          <p>
            Zastrzegamy sobie prawo do wprowadzania zmian w niniejszej Polityce Prywatności. O wszelkich zmianach będziemy informować Uczestników za pośrednictwem naszej platformy lub drogą e-mailową.
          </p>
          <h4>8. Kontakt</h4>
          <p>
            W przypadku pytań dotyczących naszej Polityki Prywatności lub przetwarzania danych osobowych, prosimy o kontakt poprzez formularz kontaktowy dostępny na naszej stronie lub bezpośrednio na adres e-mail: privacy@warsztatyfoto.pl
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
