import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { ArrowBarRight, ArrowBarLeft } from 'react-bootstrap-icons';

const SlidingNavBar = ({ user, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef(null);
  const buttonRef = useRef(null);
  const location = useLocation();

  const appPath = location.pathname.split('/')[1] || '/';
  const appTitle = appPath === '/' ? 'Główna Aplikacja' : `Aplikacja ${appPath}`;

  const toggleMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && navRef.current && !navRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <Button
        ref={buttonRef}
        onClick={toggleMenu}
        className="position-fixed start-0 top-50 translate-middle-y z-3"
        variant="outline-dark"
        style={{
          width: '48px',
          height: '120px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
          borderRadius: '0 8px 8px 0',
          borderLeft: 'none',
          boxShadow: '2px 2px 5px rgba(0,0,0,0.1)',
          transform: isOpen ? 'translateX(250px)' : 'translateX(0)',
          transition: 'transform 0.3s ease-in-out'
        }}
      >
        {isOpen ? <ArrowBarLeft size={24} /> : <ArrowBarRight size={24} />}
      </Button>

      <Navbar
        ref={navRef}
        bg="light"
        className="d-flex flex-column align-items-start position-fixed top-0 start-0 h-100 p-3"
        style={{
          width: '250px',
          transform: isOpen ? 'translateX(0)' : 'translateX(-100%)',
          transition: 'transform 0.3s ease-in-out',
          zIndex: 2,
          boxShadow: isOpen ? '2px 0 5px rgba(0,0,0,0.1)' : 'none'
        }}
      >
        <Navbar.Brand as={Link} to="/" className="mb-4 w-100 text-center">{appTitle}</Navbar.Brand>
        <Nav className="flex-column w-100">
          <Button
            as={Link}
            to="/"
            variant="outline-secondary"
            className="mb-2 text-start"
            onClick={toggleMenu}
          >
            Strona główna
          </Button>
          {user && (
            <>
              <Button as={Link}
                to="/dashboard"
                variant="outline-secondary"
                className="mb-2 text-start"
                onClick={toggleMenu}
              >
                Panel
              </Button>
              <Button as={Link}
                to="/workshops"
                variant="outline-secondary"
                className="mb-2 text-start"
                onClick={toggleMenu}
              >
                Warsztaty
              </Button>
            </>
          )}
          <Button as={Link}
            to="/kontakt"
            variant="outline-secondary"
            className="mb-2 text-start"
            onClick={toggleMenu}
          >
            Kontakt
          </Button>
          <Button as={Link}
            to="/polityka-prywatnosci"
            variant="outline-secondary"
            className="mb-2 text-start"
            onClick={toggleMenu}
          >
            Polityka Prywatności
          </Button>
        </Nav>
        <div className="mt-auto w-100">
          {user ? (
            <Button variant="danger" onClick={() => { onLogout(); toggleMenu(); }} className="w-100">
              Wyloguj
            </Button>
          ) : (
            <Button as={Link} to="/login" variant="primary" className="w-100" onClick={toggleMenu} >
              Zaloguj
            </Button>
          )}
        </div>
      </Navbar>
    </>
  );
};

export default SlidingNavBar;
