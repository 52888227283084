import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Carousel, Row, Col } from 'react-bootstrap';

// New component for random image selection
const RandomImage = ({ className }) => {
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    const fetchRandomImage = async () => {
      try {
        const response = await fetch('/images/files.json');
        const data = await response.json();
        const images = data[className] || [];
        if (images.length > 0) {
          const randomIndex = Math.floor(Math.random() * images.length);
          setImageSrc(`/images/${images[randomIndex]}`);
        }
      } catch (error) {
        console.error('Error fetching random image:', error);
      }
    };

    fetchRandomImage();
  }, [className]);

  return imageSrc ? <img src={imageSrc} alt="Random" className="d-block w-100" /> : null;
};

function PublicPage() {
  return (
    <Container className="mt-4">
      {/* Jumbotron Section */}
      <Card className="text-center p-4 my-4">
        <Card.Body>
          <Card.Title as="h1">Warsztaty Fotograficzne</Card.Title>
          <Card.Text>
            Dołącz do naszych warsztatów i rozwiń swoje umiejętności fotograficzne!
          </Card.Text>
          <Button variant="primary" href="#call-to-action">
            Zapisz się teraz
          </Button>
        </Card.Body>
      </Card>

      {/* Call to Action Section */}
      <section id="call-to-action" className="my-4 text-center">
        <h2>Nie czekaj, zarezerwuj swoje miejsce już dziś!</h2>
        <p>
          Oferujemy różnorodne warsztaty dla początkujących i zaawansowanych fotografów.
        </p>
        <Button variant="success" href="#workshops">
          Zobacz więcej
        </Button>
      </section>

      {/* Carousel Section */}
      <section id="carousel" className="my-4">
        <h2 className="text-center">Galeria Zdjęć</h2>
        <Carousel interval="1000">
          <Carousel.Item>
            <RandomImage className="landscapes" />
            <Carousel.Caption>
              <h3>Warsztat Plenerowy</h3>
              <p>Zdjęcia z ostatniego warsztatu w plenerze.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <RandomImage className="landscapes" />
            <Carousel.Caption>
              <h3>Warsztat Studyjny</h3>
              <p>Praktyka w studio fotograficznym.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <RandomImage className="landscapes" />
            <Carousel.Caption>
              <h3>Warsztat Nocny</h3>
              <p>Nocna fotografia w mieście.</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>

      {/* Workshop Cards Section */}
      <section id="workshops" className="my-4">
        <h2 className="text-center">Nasze Warsztaty</h2>
        <Row>
          <Col md={4}>
            <Card>
              <RandomImage className="squares" />
              <Card.Body>
                <Card.Title>Warsztat Plenerowy</Card.Title>
                <Card.Text>
                  Naucz się robić niesamowite zdjęcia w naturalnym oświetleniu.
                </Card.Text>
                <Button variant="primary">Dowiedz się więcej</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <RandomImage className="squares" />
              <Card.Body>
                <Card.Title>Warsztat Studyjny</Card.Title>
                <Card.Text>
                  Poznaj tajniki pracy w studio fotograficznym.
                </Card.Text>
                <Button variant="primary">Dowiedz się więcej</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <RandomImage className="squares" />
              <Card.Body>
                <Card.Title>Warsztat Nocny</Card.Title>
                <Card.Text>
                  Odkryj magię fotografii nocnej.
                </Card.Text>
                <Button variant="primary">Dowiedz się więcej</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}

export default PublicPage;
