import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Table, Button, Alert } from 'react-bootstrap';
import WorkshopForm from './WorkshopForm';

const API_URL = 'https://foto.glon.org/api';

const WorkshopList = ({ user }) => {
  const [workshops, setWorkshops] = useState([]);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editingWorkshopId, setEditingWorkshopId] = useState(null);

  useEffect(() => {
    fetchWorkshops();
  }, []);

  const fetchWorkshops = async () => {
    try {
      const response = await axios.get(`${API_URL}/workshops`);
      setWorkshops(response.data);
    } catch (error) {
      setError('Error fetching workshops');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this workshop?')) {
      try {
        await axios.delete(`${API_URL}/workshops/${id}`);
        fetchWorkshops();
      } catch (error) {
        setError('Error deleting workshop');
      }
    }
  };

  const handleShowModal = (id = null) => {
    setEditingWorkshopId(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingWorkshopId(null);
  };

  return (
    <div className="container mt-4">
      <h2>Workshops</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {user.role === 'admin' && (
        <Button onClick={() => handleShowModal()} variant="primary" className="mb-3">
          Create New Workshop
        </Button>
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {workshops.map((workshop) => (
            <tr key={workshop.id}>
              <td>{workshop.title}</td>
              <td>{workshop.start_date}</td>
              <td>{workshop.end_date}</td>
              <td>
                <Button
                  as={Link}
                  to={`/workshop/${workshop.id}`}
                  variant="info"
                  size="sm"
                  className="me-2"
                >
                  View
                </Button>
                {user.role === 'admin' && (
                  <>
                    <Button
                      onClick={() => handleShowModal(workshop.id)}
                      variant="warning"
                      size="sm"
                      className="me-2"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(workshop.id)}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <WorkshopForm
        show={showModal}
        handleClose={handleCloseModal}
        workshopId={editingWorkshopId}
        onWorkshopUpdated={fetchWorkshops}
      />
    </div>
  );
};

export default WorkshopList;
