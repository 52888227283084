import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Card, Button, Row, Col, Alert } from 'react-bootstrap';
import WorkshopParticipants from './WorkshopParticipants';
import TaskManagement from './TaskManagement';
import WorkshopForm from './WorkshopForm';
import Spinner from './Spinner';
import WorkshopPhotosGallery from './WorkshopPhotosGallery';


const API_URL = '/api';

function WorkshopDetails({ user }) {
  const { id } = useParams();
  const [workshop, setWorkshop] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchWorkshopDetails();
  }, [id]);

  const fetchWorkshopDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_URL}/workshops/${id}`);
      setWorkshop(response.data);
      setError('');
    } catch (error) {
      console.error('Error fetching workshop details:', error);
      setError('Nie udało się pobrać szczegółów warsztatu.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner message="Ładowanie szczegółów warsztatu..." />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!workshop) {
    return <Alert variant="warning">Nie znaleziono warsztatu.</Alert>;
  }

  const isAdmin = user && user.role === 'admin';

  return (
    <Container fluid className="my-4">
      <Row className="gy-2">
        <Col lg={isAdmin ? 4 : 12} md={isAdmin ? 6 : 12}>
          <Card className="h-100">
            <Card.Body>
              <Card.Title as="h1">{workshop.title}</Card.Title>
              <Card.Text>{workshop.description}</Card.Text>
              <Card.Text>Data rozpoczęcia: {new Date(workshop.start_date).toLocaleDateString()}</Card.Text>
              <Card.Text>Data zakończenia: {new Date(workshop.end_date).toLocaleDateString()}</Card.Text>
              {isAdmin && (
                <Button variant="primary" onClick={() => setShowEditModal(true)}>
                  Edytuj warsztat
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
        {isAdmin && (
          <>
            <Col lg={4} md={6}>
              <Card className="h-100">
                <Card.Body>
                  <Card.Title as="h2">Zadania</Card.Title>
                  <TaskManagement workshopId={id} />
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={6}>
              <Card className="h-100">
                <Card.Body>
                  <Card.Title as="h2">Uczestnicy</Card.Title>
                  <WorkshopParticipants workshopId={id} />
                </Card.Body>
              </Card>
            </Col>
          </>
        )}
      </Row>
	  <div><h1>Zdjęcia</h1></div>
	        <WorkshopPhotosGallery workshopId={id} />
      {isAdmin && (
        <WorkshopForm
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          workshopId={id}
          onWorkshopUpdated={fetchWorkshopDetails}
        />
      )}
    </Container>
  );
}

export default WorkshopDetails;
