import React from 'react';
import { Card } from 'react-bootstrap';

function UserInfo({ user }) { 
  return (
	<Card>
      <Card.Body>
        <Card.Title>Witaj, {user.name}/{user.role} – {user.email}!</Card.Title>
        <Card.Text>
          Jesteś zalogowany.
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default UserInfo;
