import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, ListGroup, Button, Form, Alert } from 'react-bootstrap';
import Spinner from './Spinner';

const API_URL = '/api';

function WorkshopParticipants({ workshopId }) {
  const [participants, setParticipants] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [workshopId]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      await Promise.all([fetchParticipants(), fetchAllUsers()]);
    } catch (error) {
      setError('Błąd podczas pobierania danych.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchParticipants = async () => {
    try {
      const response = await axios.get(`${API_URL}/workshops/${workshopId}/participants`);
      setParticipants(response.data);
    } catch (error) {
      throw new Error('Błąd podczas pobierania uczestników.');
    }
  };

  const fetchAllUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/users`);
      setAllUsers(response.data);
    } catch (error) {
      throw new Error('Błąd podczas pobierania listy użytkowników.');
    }
  };

  const handleAddParticipant = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!selectedUser) {
      setError('Proszę wybrać użytkownika.');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/workshops/${workshopId}/participants`, { user_id: selectedUser });
      await fetchParticipants();
      setSelectedUser('');
      setSuccess(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError('Ten użytkownik jest już uczestnikiem warsztatu.');
      } else {
        setError('Błąd podczas dodawania uczestnika.');
      }
    }
  };

  const handleRemoveParticipant = async (userId) => {
    setError('');
    setSuccess('');

    try {
      const response = await axios.delete(`${API_URL}/workshops/${workshopId}/participants/${userId}`);
      await fetchParticipants();
      setSuccess(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError('Nie znaleziono uczestnika w tym warsztacie.');
      } else {
        setError('Błąd podczas usuwania uczestnika.');
      }
    }
  };

  const availableUsers = allUsers.filter(user =>
    !participants.some(participant => participant.id === user.id)
  );

  if (isLoading) {
    return <Spinner message="Ładowanie uczestników..." />;
  }

  return (
    <Card className="mt-4">
      <Card.Body>
        <Card.Title>Uczestnicy warsztatu</Card.Title>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        <ListGroup>
          {participants.map(participant => (
            <ListGroup.Item key={participant.id} className="d-flex justify-content-between align-items-center">
              <div>
                <strong>{participant.name}</strong> <br />
                <small>{participant.email}</small>
              </div>
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleRemoveParticipant(participant.id)}
              >
                Usuń
              </Button>
            </ListGroup.Item>
          ))}
        </ListGroup>
        <Form onSubmit={handleAddParticipant} className="mt-3">
          <Form.Group>
            <Form.Control
              as="select"
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option value="">Wybierz użytkownika</option>
              {availableUsers.map(user => (
                <option key={user.id} value={user.id}>
                  {user.name} ({user.email})
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Button type="submit" variant="success" className="mt-2">
            Dodaj uczestnika
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default WorkshopParticipants;
