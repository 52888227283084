import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import UserInfo from '../components/UserInfo';
//import UserWorkshops from '../components/UserWorkshops';

import UserDashboard from '../components/UserDashboard';


function PrivatePage({ user }) {
  return (
    <Container className="mt-4">
      <h1>Panel użytkownika</h1>
	  <Row> <Col md={4}>
      <UserInfo user={user} />
	  {/* <p>To jest prywatna strona dostępna tylko dla zalogowanych użytkowników.</p> */}
	  </Col>
	  <Col md={8}>
	            <UserDashboard user={user} />
	  {/* <UserWorkshops user={user} /> */}
	  </Col></Row>
    </Container>
  );
}

export default PrivatePage;
