import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const Contact = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you would typically handle the form submission
    console.log('Form submitted');
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col md={6} className="mx-auto">
          <h2 className="text-center mb-4">Kontakt</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Imię i Nazwisko</Form.Label>
              <Form.Control type="text" placeholder="Wprowadź swoje imię i nazwisko" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Adres Email</Form.Label>
              <Form.Control type="email" placeholder="Wprowadź swój adres email" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formMessage">
              <Form.Label>Wiadomość</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Wprowadź swoją wiadomość" required />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100">
              Wyślij
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
