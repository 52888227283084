import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoginForm from './components/LoginForm';
import PublicPage from './pages/PublicPage2';
import PrivatePage from './pages/PrivatePage';
import SlidingNavBar from './components/SlidingNavBar';
import BodyBackground from './components/BodyBackground';
import backgroundImage from './assets/pb-bg2.jpg';

// Existing component imports
import WorkshopList from './components/WorkshopList';
import WorkshopForm from './components/WorkshopForm';
import WorkshopDetails from './components/WorkshopDetails';
import TaskDetailsPage from './components/TaskDetailsPage';

// New component imports
import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';

const API_URL = 'https://foto.glon.org/auth-api/api.php';
axios.defaults.withCredentials = true;

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const checkLoginStatus = async () => {
    try {
      const response = await axios.get(`${API_URL}`);
      if (response.data.success) {
        setUser(response.data.data);
      }
    } catch (error) {
      console.error('Error checking login status:', error);
    }
  };

  const handleLogin = async (email, password) => {
    try {
      const response = await axios.post(API_URL, {
        action: 'login',
        email,
        password
      });
      if (response.data.success) {
        setUser(response.data.data);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post(API_URL, {
        action: 'logout'
      });
      if (response.data.success) {
        setUser(null);
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const ForceLogout = () => {
    const navigate = useNavigate();

    useEffect(() => {
      handleLogout().then(() => {
        navigate('/', { replace: true });
      });
    }, [navigate]);

    return <div>Wylogowywanie...</div>;
  };

  return (
    <Router>
      <div className="container">
        <div className="App">
          <BodyBackground imageUrl={backgroundImage} />
          <SlidingNavBar user={user} onLogout={handleLogout} />
          <Routes>
            <Route path="/" element={<PublicPage />} />
            <Route path="/login"
              element={user ? <Navigate to="/dashboard" replace /> : <LoginForm onLogin={handleLogin} />}
            />
            <Route path="/dashboard"
              element={user ? <PrivatePage user={user} /> : <Navigate to="/login" replace />}
            />
            <Route path="/workshops"
              element={user ? <WorkshopList user={user} /> : <Navigate to="/login" replace />}
            />
            <Route path="/workshop/new"
              element={user ? <WorkshopForm /> : <Navigate to="/login" replace />}
            />
            <Route path="/workshop/:id/edit"
              element={user ? <WorkshopForm /> : <Navigate to="/login" replace />}
            />
            <Route
              path="/workshop/:id" element={user ? <WorkshopDetails user={user}/> : <Navigate to="/login" replace />}
            />
            <Route path="/task/:taskId"
              element={user ? <TaskDetailsPage user={user} /> : <Navigate to="/login" replace />}
            />
            <Route path="/force-logout" element={<ForceLogout />} />

            {/* New routes for Contact and Privacy Policy */}
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/polityka-prywatnosci" element={<PrivacyPolicy />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
