import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Alert, Modal } from 'react-bootstrap';

const API_URL = 'https://foto.glon.org/api';

const WorkshopForm = ({ show, handleClose, workshopId, onWorkshopUpdated }) => {
  const getTodayDate = () => new Date().toISOString().split('T')[0];
  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
  };

  const [workshop, setWorkshop] = useState({
    title: '',
    description: '',
    start_date: getTodayDate(),
    end_date: getTomorrowDate()
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (workshopId) {
      fetchWorkshop();
    } else {
      setWorkshop({
        title: '',
        description: '',
        start_date: getTodayDate(),
        end_date: getTomorrowDate()
      });
    }
  }, [workshopId]);

  const fetchWorkshop = async () => {
    try {
      const response = await axios.get(`${API_URL}/workshops/${workshopId}`);
      setWorkshop(response.data);
    } catch (error) {
      setError('Error fetching workshop details');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWorkshop(prevWorkshop => ({
      ...prevWorkshop,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      if (workshopId) {
        await axios.put(`${API_URL}/workshops/${workshopId}`, workshop);
        setSuccess('Workshop updated successfully');
      } else {
        await axios.post(`${API_URL}/workshops`, workshop);
        setSuccess('Workshop created successfully');
      }
      onWorkshopUpdated();
      setTimeout(() => {
        handleClose();
        setSuccess('');
      }, 2000);
    } catch (error) {
      setError('Error saving workshop');
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{workshopId ? 'Edit Workshop' : 'Create New Workshop'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={workshop.title}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={workshop.description}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              name="start_date"
              value={workshop.start_date}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              name="end_date"
              value={workshop.end_date}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            {workshopId ? 'Update Workshop' : 'Create Workshop'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default WorkshopForm;
