import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Image, Spinner, Alert, Form } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

const API_URL = '/api';

const WorkshopPhotosGallery = ({ workshopId }) => {
  const [photos, setPhotos] = useState([]);
  const [sortedPhotos, setSortedPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('default');

  useEffect(() => {
    const fetchPhotos = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_URL}/workshops/${workshopId}/photos`);
        setPhotos(response.data);
        setSortedPhotos(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching photos:', err);
        setError('Nie udało się pobrać zdjęć. Spróbuj ponownie później.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPhotos();
  }, [workshopId]);

  useEffect(() => {
    sortPhotos(sortBy);
  }, [sortBy, photos]);

  const sortPhotos = (sortType) => {
    let sorted = [...photos];
    switch(sortType) {
      case 'task':
        sorted.sort((a, b) => a.task.title.localeCompare(b.task.title));
        break;
      case 'author':
        sorted.sort((a, b) => a.user.name.localeCompare(b.user.name));
        break;
      default:
        // Do nothing, keep original order
    }
    setSortedPhotos(sorted);
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  if (isLoading) {
    return <Spinner animation="border" role="status"><span className="visually-hidden">Ładowanie...</span></Spinner>;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (photos.length === 0) {
    return <Alert variant="info">Brak zdjęć dla tego warsztatu.</Alert>;
  }

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Sortuj według:</Form.Label>
        <Form.Select value={sortBy} onChange={handleSortChange}>
          <option value="default">Domyślnie</option>
          <option value="task">Zadania</option>
          <option value="author">Autora</option>
        </Form.Select>
      </Form.Group>

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {sortedPhotos.map((photo) => (
          <div key={photo.id}>
            <Card className="mb-4">
              <Image src={`/uploads/mini/${photo.file_path}`} alt={`Zdjęcie ${photo.id}`} fluid />
              <Card.Body>
                <Card.Title>{photo.task.title}</Card.Title>
                <Card.Text>{photo.task.description}</Card.Text>
                <Card.Text>
                  <small className="text-muted">
                    Autor: {photo.user.name} ({photo.user.email})
                  </small>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </Masonry>
    </>
  );
};

export default WorkshopPhotosGallery;
