import React from 'react';
import { Spinner as BootstrapSpinner, Container } from 'react-bootstrap';

function Spinner({ message = 'Ładowanie...' }) {
  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
      <div className="text-center">
        <BootstrapSpinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Ładowanie...</span>
        </BootstrapSpinner>
        <p className="mt-2">{message}</p>
      </div>
    </Container>
  );
}

export default Spinner;
