import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { Modal, Button, ProgressBar, Image } from 'react-bootstrap';

const API_URL = '/api';

const PhotoUploader = ({ taskId, onPhotoUploaded }) => {
  const [showModal, setShowModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false
  });

  const uploadPhoto = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('photo', selectedFile);

    try {
      const response = await axios.post(`${API_URL}/tasks/${taskId}/photos`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });

      onPhotoUploaded(response.data);
      setShowModal(false);
      setUploadProgress(0);
      setPreviewImage(null);
      setSelectedFile(null);
    } catch (error) {
      console.error('Error uploading photo:', error);
      alert('Wystąpił błąd podczas przesyłania zdjęcia.');
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setUploadProgress(0);
    setPreviewImage(null);
    setSelectedFile(null);
  };

  return (
    <>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Dodaj zdjęcie
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Prześlij zdjęcie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div {...getRootProps()} className="dropzone mb-3 p-3 border rounded text-center">
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Upuść zdjęcie tutaj...</p>
            ) : (
              <p>Przeciągnij i upuść zdjęcie tutaj lub kliknij, aby wybrać plik</p>
            )}
          </div>
          {previewImage && (
            <Image src={previewImage} alt="Preview" thumbnail className="mb-3" style={{ maxWidth: '100%' }} />
          )}
          {uploadProgress > 0 && (
            <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} className="mb-3" />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={uploadPhoto} disabled={!selectedFile}>
            Prześlij
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PhotoUploader;
