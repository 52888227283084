import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Card, Image, Alert } from 'react-bootstrap';
import PhotoUploader from './PhotoUploader';
import Spinner from './Spinner';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const API_URL = '/api';

const TaskDetailsPage = ({ user }) => {
  const { taskId } = useParams();
  const [task, setTask] = useState(null);
  const [userPhotos, setUserPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [lightboxIndex, setLightboxIndex] = useState(-1);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError('');
      try {
        await Promise.all([fetchTaskDetails(), fetchUserPhotos()]);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Wystąpił błąd podczas ładowania danych.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [taskId, user.id]);

  const fetchTaskDetails = async () => {
    const response = await axios.get(`${API_URL}/tasks/${taskId}`);
    setTask(response.data);
  };

  const fetchUserPhotos = async () => {
    const response = await axios.get(`${API_URL}/tasks/${taskId}/photos?user_id=${user.id}`);
    setUserPhotos(response.data);
  };

  const handlePhotoUploaded = async () => {
    await fetchUserPhotos();
  };

  if (isLoading) {
    return <Spinner message="Ładowanie szczegółów zadania..." />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!task) {
    return <Alert variant="warning">Nie znaleziono zadania.</Alert>;
  }

  const canUploadMore = task.required_photos === 0 || userPhotos.length < task.required_photos;

  const lightboxPhotos = userPhotos.map(photo => ({
    src: `/uploads/${photo.file_path}`,
    alt: "Przesłane zdjęcie"
  }));

  return (
    <Card>
      <Card.Body>
        <Card.Title>{task.title}</Card.Title>
        <Card.Text>{task.description}</Card.Text>
        <Card.Text>
          Wymagana liczba zdjęć: {task.required_photos === 0 ? 'Bez limitu' : task.required_photos}
        </Card.Text>
        <Card.Text>
          Twoje przesłane zdjęcia: {userPhotos.length} / {task.required_photos === 0 ? '∞' : task.required_photos}
        </Card.Text>

        <h6>Twoje zdjęcia:</h6>
        {userPhotos.length === 0 ? (
          <p>Nie przesłałeś jeszcze żadnych zdjęć do tego zadania.</p>
        ) : (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {userPhotos.map((photo, index) => (
              <div
                key={photo.id}
                style={{
                  width: '100px',
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#f8f9fa',
                  cursor: 'pointer',
                }}
                onClick={() => setLightboxIndex(index)}
              >
                <Image
                  src={`/uploads/mini/${photo.file_path}`}
                  alt="Przesłane zdjęcie"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                  }}
                />
              </div>
            ))}
          </div>
        )}

        <Lightbox
          open={lightboxIndex >= 0}
          index={lightboxIndex}
          close={() => setLightboxIndex(-1)}
          slides={lightboxPhotos}
        />

        {canUploadMore ? (
          <PhotoUploader
            taskId={taskId}
            onPhotoUploaded={handlePhotoUploaded}
          />
        ) : (
          <Alert variant="warning">Osiągnąłeś limit zdjęć dla tego zadania.</Alert>
        )}
      </Card.Body>
    </Card>
  );
}

export default TaskDetailsPage;
