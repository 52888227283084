import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, ListGroup, Button, Form, Modal, Alert } from 'react-bootstrap';
import Spinner from './Spinner';

const API_URL = '/api';

function TaskManagement({ workshopId }) {
  const [tasks, setTasks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentTask, setCurrentTask] = useState({ title: '', description: '', required_photos: 1 });
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchTasks();
  }, [workshopId]);

  const fetchTasks = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_URL}/workshops/${workshopId}/tasks`);
      setTasks(response.data);
      setError('');
    } catch (error) {
      setError('Błąd podczas pobierania zadań.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowModal = (task = null) => {
    if (task) {
      setCurrentTask(task);
      setIsEditing(true);
    } else {
      setCurrentTask({ title: '', description: '', required_photos: 1 });
      setIsEditing(false);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentTask({ title: '', description: '', required_photos: 1 });
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentTask({ ...currentTask, [name]: name === 'required_photos' ? parseInt(value) : value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    try {
      if (isEditing) {
        await axios.put(`${API_URL}/tasks/${currentTask.id}`, currentTask);
        setSuccess('Zadanie zaktualizowane pomyślnie.');
      } else {
        await axios.post(`${API_URL}/workshops/${workshopId}/tasks`, currentTask);
        setSuccess('Zadanie dodane pomyślnie.');
      }
      await fetchTasks();
      handleCloseModal();
    } catch (error) {
      setError('Błąd podczas zapisywania zadania.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (taskId) => {
    if (window.confirm('Czy na pewno chcesz usunąć to zadanie?')) {
      setIsLoading(true);
      try {
        await axios.delete(`${API_URL}/tasks/${taskId}`);
        setSuccess('Zadanie usunięte pomyślnie.');
        await fetchTasks();
      } catch (error) {
        setError('Błąd podczas usuwania zadania.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isLoading && tasks.length === 0) {
    return <Spinner message="Ładowanie zadań..." />;
  }

  return (
    <Card className="mt-4">
      <Card.Body>
        <Card.Title>Zadania warsztatu</Card.Title>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        <Button variant="primary" onClick={() => handleShowModal()} className="mb-3">
          Dodaj nowe zadanie
        </Button>
        {isLoading ? (
          <Spinner message="Aktualizowanie zadań..." />
        ) : (
          <ListGroup>
            {tasks.map(task => (
              <ListGroup.Item key={task.id} className="d-flex justify-content-between align-items-center">
                <div>
                  <strong>{task.title}</strong>
                  <br />
                  <small>{task.description}</small>
                  <br />
                  <small>Wymagane zdjęcia: {task.required_photos}</small>
                </div>
                <div>
                  <Button variant="info" size="sm" className="me-2" onClick={() => handleShowModal(task)}>
                    Edytuj
                  </Button>
                  <Button variant="danger" size="sm" onClick={() => handleDelete(task.id)}>
                    Usuń
                  </Button>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? 'Edytuj zadanie' : 'Dodaj nowe zadanie'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Tytuł</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={currentTask.title}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Opis</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={currentTask.description}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Wymagana liczba zdjęć</Form.Label>
                <Form.Control
                  type="number"
                  name="required_photos"
                  value={currentTask.required_photos}
                  onChange={handleInputChange}
                  min="1"
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? 'Zapisywanie...' : (isEditing ? 'Zaktualizuj' : 'Dodaj')}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </Card.Body>
    </Card>
  );
}

export default TaskManagement;
